import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAirport, LazySvgoAlldentalLogoVertical, LazySvgoAlldiagLogoVertical, LazySvgoAllestetisLogoVertical, LazySvgoAllmedicaLogoHorizontal, LazySvgoAllmedicaLogoVertical, LazySvgoCircleDecorator, LazySvgoClientReview, LazySvgoDecorator1, LazySvgoDecorator2, LazySvgoDecorator3, LazySvgoDecorator4, LazySvgoDots, LazySvgoFlagsAustria, LazySvgoFlagsBulgaria, LazySvgoFlagsCzechia, LazySvgoFlagsDenmark, LazySvgoFlagsEstonia, LazySvgoFlagsFinland, LazySvgoFlagsGermany, LazySvgoFlagsIreland, LazySvgoFlagsLithuania, LazySvgoFlagsNorway, LazySvgoFlagsPoland, LazySvgoFlagsScotland, LazySvgoFlagsSlovakia, LazySvgoFlagsSpain, LazySvgoFlagsSweden, LazySvgoFlagsUkraine, LazySvgoFlagsUnitedKingdom, LazySvgoFlagsUnitedStates, LazySvgoHeart, LazySvgoPhoneLarge, LazySvgoQuote, LazySvgoSmilingFaceWithHeartEyes, LazySvgoStar2, LazySvgoStarShine, LazySvgoStar, LazySvgoSurgery, LazySvgoTimer, LazySvgoTrustpilotLogo } from '#components'
const lazyGlobalComponents = [
  ["SvgoAirport", LazySvgoAirport],
["SvgoAlldentalLogoVertical", LazySvgoAlldentalLogoVertical],
["SvgoAlldiagLogoVertical", LazySvgoAlldiagLogoVertical],
["SvgoAllestetisLogoVertical", LazySvgoAllestetisLogoVertical],
["SvgoAllmedicaLogoHorizontal", LazySvgoAllmedicaLogoHorizontal],
["SvgoAllmedicaLogoVertical", LazySvgoAllmedicaLogoVertical],
["SvgoCircleDecorator", LazySvgoCircleDecorator],
["SvgoClientReview", LazySvgoClientReview],
["SvgoDecorator1", LazySvgoDecorator1],
["SvgoDecorator2", LazySvgoDecorator2],
["SvgoDecorator3", LazySvgoDecorator3],
["SvgoDecorator4", LazySvgoDecorator4],
["SvgoDots", LazySvgoDots],
["SvgoFlagsAustria", LazySvgoFlagsAustria],
["SvgoFlagsBulgaria", LazySvgoFlagsBulgaria],
["SvgoFlagsCzechia", LazySvgoFlagsCzechia],
["SvgoFlagsDenmark", LazySvgoFlagsDenmark],
["SvgoFlagsEstonia", LazySvgoFlagsEstonia],
["SvgoFlagsFinland", LazySvgoFlagsFinland],
["SvgoFlagsGermany", LazySvgoFlagsGermany],
["SvgoFlagsIreland", LazySvgoFlagsIreland],
["SvgoFlagsLithuania", LazySvgoFlagsLithuania],
["SvgoFlagsNorway", LazySvgoFlagsNorway],
["SvgoFlagsPoland", LazySvgoFlagsPoland],
["SvgoFlagsScotland", LazySvgoFlagsScotland],
["SvgoFlagsSlovakia", LazySvgoFlagsSlovakia],
["SvgoFlagsSpain", LazySvgoFlagsSpain],
["SvgoFlagsSweden", LazySvgoFlagsSweden],
["SvgoFlagsUkraine", LazySvgoFlagsUkraine],
["SvgoFlagsUnitedKingdom", LazySvgoFlagsUnitedKingdom],
["SvgoFlagsUnitedStates", LazySvgoFlagsUnitedStates],
["SvgoHeart", LazySvgoHeart],
["SvgoPhoneLarge", LazySvgoPhoneLarge],
["SvgoQuote", LazySvgoQuote],
["SvgoSmilingFaceWithHeartEyes", LazySvgoSmilingFaceWithHeartEyes],
["SvgoStar2", LazySvgoStar2],
["SvgoStarShine", LazySvgoStarShine],
["SvgoStar", LazySvgoStar],
["SvgoSurgery", LazySvgoSurgery],
["SvgoTimer", LazySvgoTimer],
["SvgoTrustpilotLogo", LazySvgoTrustpilotLogo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
